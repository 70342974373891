@import url(https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=23477c&secondary.color=7c5823);
.App {
    font-family: sans-serif;
    text-align: center;
  }

div .select {
    width: 200px;
  }

/* global styles are imported in index.css
component stles are created via a specific stylesheet
named after the component.
*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* global styles are imported in index.css
component stles are created via a specific stylesheet
named after the component.
*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* // Material Theming */
/* global styles are imported in index.css
component stles are created via a specific stylesheet
named after the component.
*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* $.mdc-theme-primary: #23477c;
$.mdc-theme-on-primary: #ffffff;
$.mdc-theme-primary-light: #5672ac;
$.mdc-theme-primary-dark: #00204f;
$.mdc-theme-secondary: #7c5823;
$.mdc-theme-secondary-light: #ae854e;
$.mdc-theme-secondary-dark: #4d2f00;
$.mdc-theme-on-secondary: #ffffff;
$.mdc-theme-surface: #1b1b1d;
$.mdc-theme-on-surface: #1b1b1d;
$.mdc-theme-background: #Fdfbff;
$.mdc-theme-on-background: #1b1b1d;*/

/* .graybox {
   background-color:#2d5da8;
} */

.main {
  height: 100%;
  width: 100%;
}

.mdc-top-app-bar {
background-color:#00204f;
color: #00204f;
padding: 5px;
}

.container {
  display: grid;
  grid-template-columns: repeat(4, 25%);
/*  grid-auto-rows: minmax(250px, auto); */
  /* grid-gap: auto; */
  background-color: #e0e0e0;
  font-family: Arial, Helvetica, sans-serif;
  vertical-align: baseline;
  margin: 0px;
  padding-top: 70px;
  align-content: middle;
}
.mdc-button__label {
  color: #fff;
}
.mdc-button--outlined {
  border: 1px solid #fff;
  color: #fff !important;
}

.App-logo {
  width: 200px;
}

.mdc-button {
  color: #ffffff;
  position: relative;
  overflow: visible;
  transition: background 400ms;

  padding: 1.5rem 1rem;
  margin: 0 5px 0 5px;
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;

  border: 2;
  border-radius: .25rem;

  cursor: pointer;
}

span.ripple {
  position: absolute; /* The absolute position we mentioned earlier */
  border-radius: 90%;
  transform: scale(10);
  animation: ripple 600ms linear;
  background-color: rgba(255, 255, 255, 0.1);
}

.mdc-layout-grid__cell {
  background-color: #cdcaca;
  color: #000000;
  padding: 8px;
  margin: 6px;
  max-width: auto !important;
}

.demo-card .mdc-card {
  margin: 5px;
  padding: 5px;
  background-color:#e0e0e0;
  border-radius: 6px;
  align-items: middle;
  align-content: middle;
  width: 100%, 370px;

}

.mdc-card .mdc-typography--headline6 {
  padding: 5px 30px 5px 30px;
  margin: auto;
  text-align: center;

}

div .mdc-select {
  padding: 10px;
  max-width: 365px;
}

